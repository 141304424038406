

import React, { useEffect, useState } from 'react';
import { Loading } from '../../../../components';
import TasksApi from '../../../../api/tasks.api';
import { openNotificationWithIcon } from '../../../../utils';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button } from 'antd';

interface Props {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  query: string;
}

export const SimplifyIECriteria = (
  {
    isLoading,
    setIsLoading,
    query,
  }: Props
) => {
  const [queryResponse, setQueryResponse] = useState("");

  useEffect(() => {
    const getTask = async () => {
      try {
        if (query) {
          setIsLoading?.(true);
          const { data } = await new TasksApi().protocolICECSummarizer(query);
          setQueryResponse(data?.['results'] || ""); // add a check for data
        }
      } catch (e: any) {
        openNotificationWithIcon("", e.response?.data?.message || "An error occurred", "error");
        setQueryResponse("");
      } finally {
        setIsLoading(false);
      }
    };
    getTask();
  }, [query, setIsLoading]);

  return (
    <>
      <h1 className="m-b">Result</h1>
      <br />
      {isLoading ? (
        <Loading />
      ) : queryResponse ? (
        <>
          <div className="query-response" style={{ whiteSpace: "pre-line" }}>{queryResponse}</div>
          <div className="tr m-t">
          </div>
          <br />
          <CopyToClipboard text={queryResponse} onCopy={() => openNotificationWithIcon("", "Text Copied", "success")}>
            <Button className="outline">Copy to Clipboard</Button>
          </CopyToClipboard>
        </>
      ) : (
        <div>Please enter text to get response from the model.</div>
      )}
    </>
  );
}

