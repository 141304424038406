import React, { useState } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, UploadProps, message } from "antd";
import { UploadFile } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import { openNotificationWithIcon } from "../../utils";
import "./file-uploader.scss";

interface Props {
  onFileSubmit: (file: UploadFile<any>) => Promise<void>;
  onFilesUploadComplete?: (files: string[]) => Promise<void>;
  formats?: string[];
  isUploadButtonDisabled?: boolean;
  multipleFiles?: boolean;
}
let acceptedFormats = [".pdf", ".doc", ".txt"];
let multipleFilesAllowed = true;

export const FileUploader = (props: Props) => {
  const { onFileSubmit, onFilesUploadComplete, formats, isUploadButtonDisabled, multipleFiles } = props;
  const [files, setFiles] = useState([] as UploadFile<any>[]);
  const [loading, setLoading] = useState(false);

  if (formats && formats.length > 0) {
    acceptedFormats = formats;
  }
  if (multipleFiles != undefined) {
    multipleFilesAllowed = multipleFiles;
  }

  const draggerProps: UploadProps = {
    name: "file",
    multiple: multipleFilesAllowed,
    accept: acceptedFormats.join(","),
    beforeUpload: (file, fileList) => {
      const isImage = ["image/jpeg", "image/png", "image/jpg"].includes(file.type);
      if (isImage) message.error("Image upload not supported!");
      else {
        setFiles([...fileList, ...files]);
      }
      return isImage;
    },
    onRemove: (file: UploadFile<any>) => {
      if (loading) {
        message.error("Cannot remove file during upload!");
      } else {
        const fileIndex = files.findIndex((item) => item.name === file.name);
        if (fileIndex !== -1) {
          setFiles([...files.slice(0, fileIndex), ...files.slice(fileIndex + 1)]);
        }
      }
    },
    fileList: files,
  };

  console.log("multipleFiles:- ", multipleFilesAllowed);
  console.log(draggerProps);

  const handleFilesSubmit = async () => {
    setLoading(true);
    const uploadedFiles: { uid: string; name: string }[] = [];
    for (const file of files) {
      try {
        await onFileSubmit(file);
        openNotificationWithIcon("", `${file.name} uploaded successfully!`, "success");
        uploadedFiles.push({ name: file.name, uid: file.uid });
      } catch {
        openNotificationWithIcon("", `Could not upload ${file.name}, please try again!`, "error");
      }
    }
    onFilesUploadComplete && onFilesUploadComplete(uploadedFiles.map((item) => item.name));
    const uploadedFileUID = uploadedFiles.map((item) => item.uid);
    setFiles([...files.filter((file) => !uploadedFileUID.includes(file.uid))]);
    setLoading(false);
  };

  return (
    <div className="attachment">
      <Dragger {...draggerProps}>
        <div className="attachment-Content">
          <CloudUploadOutlined />
          <p>
            Drag & drop file here or <span>Browse File</span>
          </p>
          {acceptedFormats.length > 0 && (
            <p className="italic">You can upload {acceptedFormats.join(" & ")} files</p>
          )}
        </div>
      </Dragger>
      <div className="m-t flex jce">
        <Button
          className="outline"
          disabled={files.length <= 0 || isUploadButtonDisabled}
          onClick={handleFilesSubmit}
          loading={loading}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};
