export enum FitStatus {
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
}

export enum DOCUMENT_STATUS {
  FILE_UPLOADED = "File Uploaded",
  DATA_EXTRACTION_STARTED = "Data Extraction Started",
  FILE_FORMATTING_AND_OCR = "File Formatting & OCR",
  OCR_COMPLETE = "OCR - Completed",
  CLINICAL_DATA_EXTRACTION_STARTED = "Clinical Data Extraction Started",
  COMPLETED = "SUCCEEDED",
  ERROR = "ERROR",
}

export enum USER_ROLES {
  SUPER_ADMIN = "Super Admin",
  ENTERPRISE_ADMIN = "Enterprise Admin",
  DATA_ADMIN = "Data Admin",
  CLINICIAN = "Clinician",
}

export enum USER_ROLES_CODE {
  CLINIC = "CLINICAL_OPS",
  CRO = "SPONSOR",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export enum MODAL_TYPE {
  ADD_EDIT_CLIENT = "addEditClient",
  ADD_EDIT_USER = "addEditUser",
  ADD_EDIT_PROJECT = "addEditProject",
  ASSIGN_PROJECT = "assignProject",
  DELETE_CONFIRMATION = "deleteConfirmation",
  EDIT_PROFILE = "editProfile",
  ADD_EDIT_USER_ROLE = "addEditUserRole",
  ADD_USER_TO_PROJECT = "addUserToProject",
  ADD_PATIENTS_TO_PROJECT = "addPatientsToProject",
  PROJECT_DOCUMENT_PROCESSING_DETAIL_STATUS = "projectDocumentProcessingDetailStatus",
  USER_MULTIPLE_ROLES = "userMultipleRoles",
  ADD_EDIT_PROJECT_CONFIGURATION = "addEditProjectConfiguration",
  FORGOT_PASSWORD = "forgotPassword",
  RESET_USER_PASSWORD = "resetUserPassword",
  CRITERIA_CATEGORIES = "criteriaCategories",
  CRITERIA_AI_ASSISTED_VIEW = "criteriaAiAssistedView",
  CRITERIA_SQL_QUERY = "criteriaSQLQuery",
  SAVE_COHORT = "saveCohort",
  UPLOAD_PATIENT_DATASET = "uploadPatientDataset",
  PATIENT_DETAILS = "patientDetails",
}

export enum THEME {
  LIGHT = "light-theme",
  DARK = "dark-theme",
}

export enum CRITERIAS {
  INCLUSION_CRITERIA = "Inclusion Criteria",
  EXCLUSION_CRITERIA = "Exclusion Criteria",
}

export enum TASK_COLLAPSIBLE_COMPONENT_HEADING {
  TASK_DESCRIPTION = "Task Description",
}
