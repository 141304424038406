import React from "react";
import { Card } from "antd";
import { ProjectActions } from "./ProjectActions";
import { getLocalDateTimeStringFromISO, PAGE_URL } from "../../../utils";
import { useNavigate } from "react-router-dom";

interface Props {
  project: IProject;
}

export const ProjectTile = ({ project }: Props) => {
  const navigate = useNavigate();
  const projectDescription =
    project.description.slice(0, -4) + (project.description.length > 300 ? "..." : "");
  return (
    <Card className="project-card-view p-20">
      <div className="flex jcsb m-b">
        <p className="project-name">{project.name}</p>
        <ProjectActions record={project} />
      </div>
      <div
        className="cursor-pointer"
        onClick={() => navigate(`${PAGE_URL.PROJECT_DETAIL}/${project.id}`)}
      >
        <h5>Project Description</h5>
        <p dangerouslySetInnerHTML={{ __html: projectDescription }} />
        <br />
        <div className="flex jcsb aic">
          <p>
            Total Subjects in Dataset <span className="highlight">1000</span>
          </p>
          <div>
            <p>
              <span className="highlight">Created on </span>
              {getLocalDateTimeStringFromISO(project.createdAt)}
            </p>
            <p>
              <span className="highlight">Updated on </span>
              {getLocalDateTimeStringFromISO(project.updatedAt)}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};
