import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { CohortCollapseIcon } from "../../assets/icons";
import { CriteriaParsingResult, CohortDrawer } from "./cohort-container";
import "./cohort.scss";

interface Props {
  displaySelectProjectDropdown?: boolean;
  isDrawerCollapsed: boolean;
  setIsDrawerCollapsed: Dispatch<SetStateAction<boolean>>;
  isCohortAsideCollapsed: boolean;
  setIsCohortAsideCollapsed: Dispatch<SetStateAction<boolean>>;
}

export const CohortContainer = (props: Props) => {
  const {
    displaySelectProjectDropdown,
    isDrawerCollapsed,
    setIsDrawerCollapsed,
    isCohortAsideCollapsed,
    setIsCohortAsideCollapsed,
  } = props;
  const [isAnalyzeCohortLoading, setIsAnalyzeCohortLoading] = useState(false);
  const cohortAsideRef = useRef<HTMLDivElement>(null);

  return (
    <div className={`cohort-drawer-container ${isDrawerCollapsed ? "sidebar-collapse" : ""} ${isCohortAsideCollapsed ? "sidebar-collapse-full" : ""}`}>
      <div className="pr">
        <div className="overlap-icon">
          {!isCohortAsideCollapsed && (
            <div
              className="cursor-pointer"
              onClick={() => {
                setIsDrawerCollapsed(!isDrawerCollapsed);
              }}
            >
              <CohortCollapseIcon className={`${isDrawerCollapsed ? "rotate-icon" : ""}`} />
            </div>
          )}
          {isDrawerCollapsed && (
            <div
              className="cursor-pointer"
              onClick={() => setIsCohortAsideCollapsed(!isCohortAsideCollapsed)}
            >
              <CohortCollapseIcon className={`${isCohortAsideCollapsed ? "rotate-icon" : ""}`} />
            </div>
          )}
        </div>
        
        {(<CohortDrawer
            {...{
              displaySelectProjectDropdown,
              isDrawerCollapsed,
              isAnalyzeCohortLoading,
              setIsDrawerCollapsed,
              setIsAnalyzeCohortLoading,
              cohortAsideRef,
            }}
          />)}
        
      </div>
      {
        <CriteriaParsingResult
          {...{ isDrawerCollapsed, isAnalyzeCohortLoading, displaySelectProjectDropdown }}
        />
      }
    </div>
  );
};
