// To Parse the CSV files on the frontend
export function parseCSVFile(file: File): Promise<string | any> {
    return new Promise((resolve, reject) => {
      try {
        if (!file) {
          throw new Error("No file selected.");
        }
  
        const extension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
        if (extension !== ".csv") {
          throw new Error("Invalid file type. Only CSV files are allowed.");
        }
  
        const reader = new FileReader();
        reader.onload = (event: any) => {
        const contents = event.target?.result as string;
        // const contents = event.target?.result as string;
        const lines = contents.split('\n');

        if (!lines[0]) {
          reject(new Error("CSV file is empty."));
          return;
        }

      const inputData = [];
        for (let i = 0; i < lines.length; i++) {
          const columns = lines[i].split(',');
          const inputColumnIndex = columns.findIndex((column) => column.trim() === "input");
          if (inputColumnIndex !== -1) {
            // Check if the row has enough columns before pushing to inputData
            if (columns.length > inputColumnIndex) {
              inputData.push(columns[inputColumnIndex]);
            }
          }
        }

        if (inputData.length === 0) {
          reject(new Error("Column 'input' not found in the CSV file."));
          return;
        }

        resolve(inputData);
        };
  
        reader.onerror = (event) => {
          reject(new Error("Error reading the file."));
          console.log("ERROR in CSV parser, AT EVENT:",event);
        };
  
        reader.readAsText(file);
      } catch (error) {
        reject(error);
      }
    });
  }
  