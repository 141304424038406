import React, { Component, useState } from "react";
import { Loading } from "../../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { GenerateSummary } from "./resultsTypes/GenerateSummaryResults";
import { StructureIECriteria } from "./resultsTypes/StructureIECriteria";
import { SimplifyIECriteria } from "./resultsTypes/SimplifyIECriteria";
// import { CohortPatients } from "../../../pages";
// import { IAnalyzeSelectedInclusionExclusionCriteria } from "../../../store/cohort";
// import { openNotificationWithIcon } from "../../../utils";
// import { useFetchCohortCriteria, useNctId } from '../../../hooks';
// import { fetchStudyFields } from "../../../api/insightsApi";
// interface Props {
//     // isLoading: boolean;
//     ResultsData: any;
//     isDrawerCollapsed: boolean;
//     isAnalyzeCohortLoading: boolean;
//     displaySelectProjectDropdown?: boolean;
//     studyFields: any[]; // Define the studyFields prop here
//     onStudyFieldsChange: (results: any) => void;

// }
interface Props {
  summaryInput: string
  cohortCriteria: {
    inclusion: string;
    exclusion: string;
  };
  simplifyCriteriaInput: string;
  setIE: React.Dispatch<React.SetStateAction<boolean>>;
  selectedInsights: string;
  updateSelectedCriteriaIds: (data: number[]) => void;
  isAnalyzeCohortLoading: any;
  isLoading: boolean;
  setIsIESimplificationLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}


export const ResultsContainer = (props: Props) => {
  const { summaryInput, setIsIESimplificationLoading, selectedInsights, simplifyCriteriaInput, cohortCriteria, isAnalyzeCohortLoading, isLoading, setIsLoading } = props;
  const getDynamicComponent = (insight: string): JSX.Element => {
    let content = null;
    switch (insight) {
      case "GenerateSummary":
        content = <GenerateSummary isLoading={isLoading} setIsLoading={setIsLoading} query={summaryInput} />;
        break;
      case "StructureIECriteria":
        content = <StructureIECriteria isloading={isLoading} cohortCriteria={cohortCriteria} />;
        break;
      case "SimplifyIECriteria":
        content = <SimplifyIECriteria isLoading={isLoading} setIsLoading={setIsLoading} query={simplifyCriteriaInput} />;
        break;
      case "AskQuestion":
        content = <p>This Feature will be updated in the near future</p>//<AskQuestion />;
        break;
    }
    if (content) return content
    else return <React.Fragment><p>
      Currently No Criteria and Insights Data is generated, please use the form in left to Analyse
      Criteria and Generate Cohort Data.
    </p></React.Fragment>
  }


  return (
    <div className="results-container">
      {isAnalyzeCohortLoading ? (
        <Loading />)
        : (
          <div>
            {getDynamicComponent(selectedInsights)}
          </div>

        )}
    </div>
  );
};
