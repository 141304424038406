import React from "react";
import { Select } from "antd";

interface Props {
  defaultValue?: string;
  value?: string;
  options: IDropdownOption[];
  loading: boolean;
  onSelect: (value: string, option: IDropdownOption) => void;
  notFoundContent?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

export const Dropdown = (props: Props) => {
  return (
    <Select
      // style={{ width: 210 }}
      className={`ai-select w-full ${props.className ? props.className : ""}`}
      // dropdownClassName="profile-info-filter"
      dropdownMatchSelectWidth={true}
      getPopupContainer={(trigger) => trigger.parentNode}
      {...props}
    ></Select>
  );
};
