//boilerplate code for upload container
import React, { useState } from "react";
import { Upload, message } from "antd";
import { FileUploader, TaskInput } from "../../../components";
import { UploadOutlined } from "@ant-design/icons";
// import "./upload.scss";
import { isEmptyObject } from "../../../utils";
import { parseCSVFile } from "../../../components/task-input/csvParser";

export const UploadContainer = () => {
    const onFileSubmit = async (file: any) => {
        try{
        //   handleFileChange;
          const inputData = await parseCSVFile(file);
          console.log(file);
          // console.log("______________________++==");
          console.log(inputData);
    
        //   if (inputData) {
        //     if (query === inputData) {
    
        //       setQuery(inputData + " ");
        //     } else {
        //       setQuery(inputData);
        //     }
        //     setIsLoading(true);
        //   }
        }
        catch(e: any){
          message.error(e.message);
        }
      };
    return(

        <><p className="m-b">Upload File</p>
        <FileUploader onFileSubmit={onFileSubmit} formats={[".doc", ".csv", ".txt"]} multipleFiles={false} /></>

    );
    }