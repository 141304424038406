import axios, { AxiosResponse } from 'axios';

const CLINICAL_NOTE_CLASSIFICATION_TASK = `https://ucbca57m6qe74dmvjkhurkzaoe0bhclf.lambda-url.us-east-1.on.aws/`;//`https://zrdc3eemqchifddwroojrkrebq0apcqg.lambda-url.us-east-1.on.aws`;
const CLINICAL_PROTOCOL_SUMMARY_TASK = `https://k32al3g6ij635iencybch3ge3q0mzqvx.lambda-url.us-east-1.on.aws`;
const CLINICAL_PROTOCOL_ICEC_SUMMARY_TASK = `https://p24zt6hpcwm664t2l4kl6tkmwq0xruxq.lambda-url.us-east-1.on.aws`;

const defaultHeaders = {
  'Content-Type': 'text/plain',
  Accept: 'application/json',
};

class TasksApi {
  
  getResponse = async (name: string, input: string): Promise<AxiosResponse> => {
    const headers = { ...defaultHeaders };
    const payload = {
      input
    };
    let taskEndpoint = CLINICAL_PROTOCOL_SUMMARY_TASK;
    if (name == 'NOTE_CLASSIFIER') {
      taskEndpoint = CLINICAL_NOTE_CLASSIFICATION_TASK;
    }
    return axios.post(taskEndpoint, payload, { headers });
  };

  classifyClinicalNote = async (input: string): Promise<AxiosResponse> => {
    console.log("node API CALL BEING MADE NWO!")
    const headers = { ...defaultHeaders };
    const payload = {
      // "body": "{\"input\": [\"Array\", \"of\", \"text\", \"inputs\"]}"

      body: `{"input": "[${input}]"}`
    };
    console.log("PLAYLOAD ========>>>>>>>>>>>>>>>>");
    console.log(payload);
    return axios.post(CLINICAL_NOTE_CLASSIFICATION_TASK, payload, { headers });
  };

  protocolSummarizer = async (input: string): Promise<AxiosResponse> => {
    const headers = { ...defaultHeaders };
    const payload = {
      input
    };
    return axios.post(CLINICAL_PROTOCOL_SUMMARY_TASK, payload, { headers });
  };

  protocolICECSummarizer = async (input: string): Promise<AxiosResponse> => {
    const headers = { ...defaultHeaders };
    const payload = {
      input
    };
    return axios.post(CLINICAL_PROTOCOL_ICEC_SUMMARY_TASK, payload, { headers });
  };
  

}

export default TasksApi;
