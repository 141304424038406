import React, { Dispatch, SetStateAction, createContext, useCallback, useRef, useState, useEffect } from "react";

import "./cohort.scss";
import { PageWrapper } from "../../component-wrappers";
import NCTIDContainer from "./NCTIDContainer";
import { Button } from "antd";
import { UploadContainer } from "./UploadContainer";
import { ErrorState, InputText } from "../../../components/task-input/InputText";
import { ResultsContainer } from "./ResultsContainer";
import { useDispatch, useSelector } from 'react-redux';
import { updateNctId } from '../../../store/cohort/slice';
import { cohortService } from "../../../api";
import { updateExclusionCriteria, updateInclusionCriteria } from "../../../store/cohort/slice";
import { RootState } from "../../../store";
import { fetchStudyFields } from "../../../api/fetchStudyfields";
import { UnmountClosed } from 'react-collapse';
import { BsChevronDown } from "react-icons/bs";

interface Props {
  displaySelectProjectDropdown?: boolean;
  isDrawerCollapsed: boolean;
  setDataSourceMethod: SetStateAction<string | undefined>;
  setIsDrawerCollapsed: Dispatch<SetStateAction<boolean>>;
  isCohortAsideCollapsed: boolean;
  setIsCohortAsideCollapsed: Dispatch<SetStateAction<boolean>>;
  isDropdownSelected: boolean;
}


export const InsightsContainer = (props: Props) => {
  const [error, setError] = useState<ErrorState>({
    textInput: "",
  });
  const [showIESimplificationInput, setShowIESimplificationInput] = useState(false);
  const [showProtocolSummaryInput, setShowProtocolSummaryInput] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isIESimplificationLoading, setIsIESimplificationLoading] = useState(false);
  const [selectedInsights, setSelectedInsights] = useState("");
  const [summaryInput, setSummaryInput] = useState("");
  const [ieSimplificationInput, setIeSimplificationInput] = useState("");
  const [dataExtracted, setDataExtracted] = useState("");
  const {
    isDrawerCollapsed,
    setDataSourceMethod,
  } = props;
  const dispatch = useDispatch();
  const [isAnalyzeCohortLoading, setIsAnalyzeCohortLoading] = useState(false);
  const [nctId, setNctId] = useState("");

  useEffect(() => {
    setDataExtracted("");
    setIsLoading(false);
    setSelectedInsights("");
    setIeSimplificationInput("");
    setSummaryInput("");
  }, [setDataSourceMethod])
  const handleNctIdChange = (value: string) => {
    dispatch(updateNctId(value));
    setNctId(value);
  };
  const [inputText, setInputText] = useState(""); //To Handle the Input Data from the textbox copy/paste MEthod
  const handleExtractClick = async () => {
    try {
      setIsAnalyzeCohortLoading(true);
      const { data } = await cohortService.getInclusionExclusionFromNctid(nctId);
      dispatch(updateInclusionCriteria(data.inc));
      dispatch(updateExclusionCriteria(data.exc));
      setDataExtracted(`${JSON.stringify(data)}`);
      const DatatoSet = await fetchStudyFields(nctId);
      const IEsimplifyData = DatatoSet.EligibilityCriteria;
      setIeSimplificationInput(IEsimplifyData[0]);
      const summaryData = await fetchStudyFields(nctId);
      setSummaryInput(`${summaryData.BriefSummary}${summaryData.BriefTitle}`);
    } catch (err: any) {
      const res =
        err.response.data?.message || "Something went wrong, please check NCTID and try again";

    } finally {
      setIsAnalyzeCohortLoading(false);
    }
  };
  const { cohortCriteria, selectedProject, analyzeCohortCriteria } = useSelector(
    (state: RootState) => state.cohort
  );

  const onInputTextChange = useCallback(
    (e: any) => {
      setError({ ...error, textInput: "" });
      setInputText(e.target.value);
      setDataExtracted("");
    },
    [error, setInputText]
  );

  let content;
  let onSummary: any;
  let onIEStructure: any;
  let onSimplifyIECriteria: any;
  switch (setDataSourceMethod) {
    case "NCTIDMethod":
      content = <NCTIDContainer nctId={nctId} onNctIdChange={handleNctIdChange} onExtractClick={handleExtractClick} />;
      onSummary = async () => {
        setSelectedInsights("GenerateSummary");
      }
      onIEStructure = async () => {
        setSelectedInsights("StructureIECriteria");
      }
      onSimplifyIECriteria = async () => {
        setSelectedInsights("SimplifyIECriteria");
      }
      break;
    case "UploadMethod":
      console.log("WILL BE UPLOAD LOADED!!");
      content = <div><UploadContainer /></div>;
      break;
    case "TextMethod":
      content = <div>
        <>
          <p className="m-b">Enter/Copy paste text</p>
          <InputText {...{ onInputTextChange, error, inputText }} />
        </>
      </div>;
      console.log("Input Text ===--= ", inputText);
      onSummary = async () => {
        setSummaryInput(inputText);
        setSelectedInsights("GenerateSummary");
      }
      onIEStructure = async () => {
        setSelectedInsights("StructureIECriteria");
      }
      onSimplifyIECriteria = async () => {
        setIeSimplificationInput(inputText);
        setSelectedInsights("SimplifyIECriteria");
      }
      break;
    default:
      content = null; // Handle the case where no method is selected
  }
  const TextDivstyle: React.CSSProperties = { width: '410px', top: '80px', float: 'left', position: 'relative', marginRight: '10px' };
  const ButtonStyle: React.CSSProperties = { top: '80px', float: 'right', position: 'relative', width: '200px' };

  return (
    <PageWrapper
      containerClassName={isDrawerCollapsed ? "no-padding-top" : "no-padding"}
      pageContainerChildren={

        <div className="cohort-drawer-container" >
          <div className="pr" > {content}
            <div style={TextDivstyle}><p >
              Generate an empathetic patient friendly summary of the trial using relatable language.
            </p></div>
            <div style={{ display: 'flex' }}>
              <Button disabled={!props.isDropdownSelected || (dataExtracted == "" && inputText == "")} className="outline" style={ButtonStyle} onClick={() => onSummary()}>Generate Protocol Summary</Button>
            </div>
            <br /><br /> 
            {
              summaryInput == '' ? <></> : (
                <div style={{ display: 'block', marginTop: '50px' }} >
                  <div style={{ display: 'flex', margin: 'auto', cursor: 'pointer' }} onClick={() => { setShowProtocolSummaryInput(!showProtocolSummaryInput) }}>
                    <p style={{ color: '#6101FF' }}>Show Input</p>
                    <BsChevronDown style={{ marginLeft: '5px', marginTop: 'auto', marginBottom: 'auto', transform: !showProtocolSummaryInput ? '' : 'rotate(180deg)' }} />
                  </div>
                  {/* <br /> */}
                  <UnmountClosed isOpened={showProtocolSummaryInput}>
                    <InputText onInputTextChange={() => { }} error={false} inputText={summaryInput} style={{height: '100px'}} />
                  </UnmountClosed>
                </div>
              )
            }

            <div style={{ display: 'flex', marginTop:  summaryInput == '' ? '40px' : '-40px' }}>
              <div style={TextDivstyle}><p >
                Extract the IE Criteria from the source and generate an xls downloadable file
              </p></div>
              <div style={{ display: 'flex' }}>
                <Button disabled={!props.isDropdownSelected || setDataSourceMethod == "TextMethod" || (dataExtracted == "" && inputText == "")} className="outline" style={ButtonStyle} onClick={() => onIEStructure()}>    Structure IE Criteria    </Button>
              </div>
            </div><br />

            <div style={{ display: 'flex', marginTop: '50px' }}>
              <div style={TextDivstyle}><p >
                Generate a matter-of-fact patient-friendly summary of the Inclusion/Exclusion Criteria in a reliable language
              </p></div>
              <div style={{ display: 'flex' }}>
                <Button disabled={!props.isDropdownSelected || (dataExtracted == "" && inputText == "")} className="outline" style={ButtonStyle} onClick={() => onSimplifyIECriteria()}>   Simplify IE Criteria    </Button>
              </div>
            </div><br /> <br/>
            {
              ieSimplificationInput == '' ? <></> : (
                <div style={{ display: 'block', marginTop: '50px' }} >
                  <div style={{ display: 'flex', margin: 'auto', cursor: 'pointer' }} onClick={() => { setShowIESimplificationInput(!showIESimplificationInput) }}>
                    <p style={{ color: '#6101FF' }}>Show Input</p>
                    <BsChevronDown style={{ marginLeft: '5px', marginTop: 'auto', marginBottom: 'auto', transform: !showIESimplificationInput ? '' : 'rotate(180deg)' }} />
                  </div>
                  {/* <br /> */}
                  <UnmountClosed isOpened={showIESimplificationInput}>
                    <InputText onInputTextChange={() => { }} error={false} inputText={ieSimplificationInput} style={{height: '100px'}}/>
                  </UnmountClosed>
                </div>
              )
            }
          </div><></>
          <div className="cohort-container">
            {<ResultsContainer
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setIsIESimplificationLoading={setIsIESimplificationLoading}
              summaryInput={summaryInput}
              selectedInsights={selectedInsights}
              cohortCriteria={cohortCriteria}
              simplifyCriteriaInput={ieSimplificationInput}


              isAnalyzeCohortLoading={isAnalyzeCohortLoading}
              updateSelectedCriteriaIds={(data) => console.log(data)} setIE={function (value: React.SetStateAction<boolean>): void {
                throw new Error("Function not implemented.");
              }} />}
          </div>
        </div>} />
  );
}

