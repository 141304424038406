import React, { useCallback, useState } from 'react';
import { Input, Button } from 'antd';

// import { fetchCohortCriteriaFromNctId } from '../../cohort/cohort-container/CohortDrawer';
// import { useFetchCohortCriteria, useNctId } from '../../../hooks';
import { useFetchCohortCriteria, useNctId } from '../../../hooks';

interface NCTIDContainerProps {
    nctId: string;
    onNctIdChange: (value: string) => void;
    onExtractClick: () => void;
  }
/**
 * A container component for the NCTID input field and extract button.
 * @param nctId - The current NCTID value.
 * @param onNctIdChange - A function to handle changes to the NCTID value.
 * @param onExtractClick - A function to handle the extract button click.
 * @returns A React component that renders the NCTID input field and extract button.
 */
export const NCTIDContainer = ({nctId, onNctIdChange, onExtractClick }: NCTIDContainerProps) => {
    const {
        isValidNctId,
        nctId:localNctid,
        nctIdValidationMessage,
        setNctId: setLocalNctId,
      } = useNctId();
      
    const {
        fetchCohortCriteriaError,
        fetchCohortCriteriaFromNctId,
        isCohortCriteriaLoading,
        setFetchCohortCriteriaError,
      } = useFetchCohortCriteria();
      
    const onExtractCriteriaSearch = useCallback(
        async (str: string) => {
            await fetchCohortCriteriaFromNctId(str);
            onExtractClick();
            console.log("NCTIDContainer Extracted Data ====", nctId, "",localNctid);
        },
        [fetchCohortCriteriaFromNctId, nctId, localNctid, onExtractClick]
    );

    return (
        <>
            <div>
                <p className="cohort-drawer-title">{`NCTID (Extract criteria from ClinicalTrials.gov)`}</p>
                <div className="flex no-wrap gp-10 aic">
                    <Input
                        className={`extract-search`}
                        placeholder="eg., NCT01640873"
                        value={localNctid}
                        onChange={(e) => {
                            setLocalNctId(e.target.value);
                            onNctIdChange(e.target.value);
                        }}
                        onPressEnter={() => onNctIdChange(localNctid)}
                    />
                    <Button
                        className="outline"
                        disabled={!isValidNctId}
                        loading={isCohortCriteriaLoading}
                        onClick={() => onExtractCriteriaSearch(localNctid)}
                    >
                        Extract
                    </Button>
                </div>
                {nctIdValidationMessage && <p className="error">{nctIdValidationMessage}</p>}
                {fetchCohortCriteriaError && <p className="error">{fetchCohortCriteriaError}</p>}
            </div>
        </>
    );
};

export default NCTIDContainer;
