import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { Dropdown, MandatoryFieldsText, TextEditor } from "../../../components";
import { useProject, useUser } from "../../../hooks";
import { USER_ROLES_CODE } from "../../../utils";

export const PATIENT_DATASET_OPTIONS: IDropdownOption[] = [
  { id: -1, value: "Select Dataset for Project" },
  { id: 1, value: "Site 1 Dataset" },
];

interface ErrorState {
  name: string;
  description: string;
  patientDataset: string;
}

interface Props {
  isEdit: boolean;
  editObj: IProject;
  onClose?: () => void;
  openUploadPatientDataset: () => void;
}

export const AddEditProjectForm = (props: Props) => {
  const { editObj, isEdit, onClose, openUploadPatientDataset } = props;
  const { createProject, editProject, isProjectCreateLoading, isProjectEditLoading } = useProject();
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDesscription] = useState("");
  const [selectedPatientDataset, setSelectedPatientDataset] = useState(PATIENT_DATASET_OPTIONS[0]);
  const [error, setError] = useState<ErrorState>({ name: "", patientDataset: "", description: "" });
  const { roleCode } = useUser();

  const onProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError({ ...error, name: "" });
    setProjectName(e.target.value);
  };

  const onProjectDescriptionChange = (str: string) => {
    setError({ ...error, description: "" });
    setProjectDesscription(str);
  };

  const onPatientDatasetSelect = (value: string, option: IDropdownOption) => {
    setError({ ...error, patientDataset: "" });
    setSelectedPatientDataset(option);
  };

  useEffect(() => {
    if (isEdit) {
      const { name, description, patientDataset } = editObj;
      setProjectName(name);
      setSelectedPatientDataset(patientDataset);
      setProjectDesscription(description);
    }
  }, [editObj, isEdit]);

  const handleEditSubmit = async () => {
    await editProject({ name: projectName, description: projectDescription }, editObj.id);
  };

  const handleAddSubmit = async () => {
    const obj: IProjectCreate = {
      name: projectName,
      description: projectDescription,
      datasetId: 1,
    };
    await createProject(obj);
  };

  const validateForm = () => {
    const err: ErrorState = { name: "", description: "", patientDataset: "" };
    let isValidForm = false;
    if (!projectName) err.name = "This field is required";
    if (!projectDescription) err.description = "This field is required";
    if (selectedPatientDataset.id < 0) err.patientDataset = "This field is required";
    if (roleCode === USER_ROLES_CODE.CRO && !err.description && !err.name) isValidForm = true;
    if (!err.description && !err.name && !err.patientDataset) isValidForm = true;
    setError(err);
    return isValidForm;
  };

  const handleSubmit = async () => {
    setError({ description: "", name: "", patientDataset: "" });
    const isValidForm = validateForm();
    if (isValidForm) {
      if (isEdit) await handleEditSubmit();
      else await handleAddSubmit();
      onClose && onClose();
    }
  };

  return (
    <div className="autonomizeModal">
      <div className="first-project-form">
        <div>
          <h3>Project Name</h3>
          <Input value={projectName} onChange={onProjectNameChange} />
          {error.name && <p className="error">{error.name}</p>}
        </div>
        {roleCode === USER_ROLES_CODE.CLINIC && (
          <div>
            {/* <h3>
              Select Participant Dataset{" "}
              <a className="" onClick={() => openUploadPatientDataset()}>
                {"(OR Connect to New Source)"}
              </a>
            </h3>
            <Dropdown
              className="ai-select w-full form-select"
              value={selectedPatientDataset.value}
              loading={false}
              onSelect={onPatientDatasetSelect}
              options={PATIENT_DATASET_OPTIONS}
            />
            {error.patientDataset && <p className="error">{error.patientDataset}</p>} */}
          </div>
        )}
      </div>
      <div className="text-editor m-t">
        <h3>Project Description</h3>
        <TextEditor
          displayToolbar
          text={projectDescription}
          setText={onProjectDescriptionChange}
          placeholder="Please enter and style project description here"
        />
        {error.description && <p className="error">{error.description}</p>}
      </div>
      <div className="tr m-t mandatory-text flex jcsb aic">
        <MandatoryFieldsText />
        <Button
          className="outline"
          loading={isProjectCreateLoading || isProjectEditLoading}
          onClick={handleSubmit}
        >
          {isEdit ? "Save" : "Add Project"}
        </Button>
      </div>
    </div>
  );
};
