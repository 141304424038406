import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { InsightsDrawer } from "../../protocol-insights/protocol-insights-container/InsightsDrawer";
// import { ResultsContainer } from "../../protocol-insights/protocol-insights-container/resultsConatiner";

interface Props {
    projectId: number;
    projectName: string;
}
const displaySelectProjectDropdown = true;


export const ProtocolInsights = (props: Props) => {
  const { projectName } = props;
  const dispatch = useDispatch();
  const [isDrawerCollapsed, setIsDrawerCollapsed] = useState(true);
  const [isCohortAsideCollapsed, setIsCohortAsideCollapsed] = useState(false);
  const selectedProject = projectName;

  return (
        <><InsightsDrawer
              isAnalyzeCohortLoading={false} setIsAnalyzeCohortLoading={function (_loading: boolean): void {
                  throw new Error("Function not implemented.");
              } } {...{
                  displaySelectProjectDropdown,
                  isDrawerCollapsed,
                  setIsDrawerCollapsed,
                  isCohortAsideCollapsed,
                  setIsCohortAsideCollapsed,
              }} /></>
  );
};

export default ProtocolInsights;
