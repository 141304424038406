import React, { useEffect, useState } from 'react';
import TasksApi from '../../../../api/tasks.api';
import { Loading } from '../../../../components';
import { openNotificationWithIcon } from '../../../../utils';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Button } from 'antd';

interface Props {
  query: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GenerateSummary = ({
  query,
  isLoading,
  setIsLoading
}: Props) => {
  const [queryResponse, setQueryResponse] = useState("");
  useEffect(() => {
    const getTask = async () => {
      try {
        if (query) {
          const { data } = await new TasksApi().protocolSummarizer(query);
          setQueryResponse(data['results']);
          console.log("queryResponse:::>>>>>", queryResponse);
        }
      } catch (e: any) {
        openNotificationWithIcon("", e.response.data.message, "error");
        setQueryResponse("");
      } finally {
        setIsLoading(false);
      }
    };
    getTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);//setIsLoading]);
  return (
    <>
      <h1 className="m-b">Result</h1>
      <br />
      {isLoading ? (
        <Loading />
      ) : queryResponse ? (
        <>
          <div className="query-response">{queryResponse}</div>
          <div className="tr m-t">
          </div>
          <CopyToClipboard text={queryResponse} onCopy={() => openNotificationWithIcon("", "Text Copied", "success")}>
          <Button className="outline">Copy to Clipboard</Button>
        </CopyToClipboard>
        </>
      ) : (
        <Loading style={{
          width: '-webkit-fill-available'
        }}/>
      )}
    </>
  );
}
