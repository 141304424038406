import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { openNotificationWithIcon } from '../../../../utils';
import { Button } from 'antd';

interface Props {
  cohortCriteria: {
    inclusion: string;
    exclusion: string;
  };
  isloading: boolean;
}
export const StructureIECriteria = (props: Props) => {
  const { cohortCriteria, isloading } = props;

  function NewlineText(text: string) {
    const newText = text.split('\n').map(str => <p key={str}>{str}</p>);
    return newText;
  }
  return (
    <div>
      <h1 className="m-b">Results</h1>
      <div>
        <p>
          <span><h3>Inclusion Criteria:</h3></span><br /><br />
          {NewlineText(cohortCriteria.inclusion)}<br /><br />
          <span><h3>Exclusion Criteria:</h3></span><br /><br />
          {NewlineText(cohortCriteria.exclusion)}
        </p>
        <br />
        <CopyToClipboard text={`Inclusion Criteria :- ${cohortCriteria.inclusion}, Exclusion Criteria :- ${cohortCriteria.exclusion}`} onCopy={() => openNotificationWithIcon("", "Text Copied", "success")}>
          <Button className="outline">Copy to Clipboard</Button>
        </CopyToClipboard>
      </div>
    </div>
  );
}

